<template>
	<div>
		<div class="MyFocus-Box" style="position: relative;margin-bottom: 32px;">
			<div class="MyFocus-title">我的关注</div>

			<div class="MyFocus-content">
				<div class="popup_content" v-for="(item, index) of listData" :key="index" :data-index="index" @click="enterTheStore(item.fShopUnitID)">
					<div class="popup_content_item">
						<div class="MyFcous-one">
							<div class="shop_pic">
								<div class="MyFocus-img">
									<img :src="item.fShopLogoPath?item.fShopLogoPath:require('@/assets/shoplogo.png')" alt="">
								</div>
							</div>
							<div class="item_right" style="margin-left: 10px;">
								<div>
									<div class="item_shop">
										<div class="shop_name hide-text" style="width:208px;">{{item.fShopName}}</div>
									</div>
									<p class="MyFoucs-pu">店铺</p>
									<p class="u_mc_smallp">{{item.fTotalFocusNum}}人关注</p>
								</div>
							</div>
						</div>
						<i class="iconfont pointer" @click.stop="DElete(item.fShopUnitID)">&#xe744;</i>
					</div>
				</div>
			</div>
			<div class="flex-row-center-center" v-if="listData.length == 0">
						<svg-icon icon-class="NoCollection2" />
			</div>
			<div  class="text-center" style="position: absolute;bottom: 32px;">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="current"
				 :page-size="pagesize" layout="prev, pager, next, jumper" :total="total" prev-text="上一页" next-text="下一页"
				 :pager-count="5" style="margin-left: 100%;">
				</el-pagination>
			</div>
		</div>

		
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	export default {
		data() {
			return {
				isConcern: false,
        total: 0, //总条数
				pagesize: 18, //每页显示条目个数
				currentPage: 1, //当前页
				listData: [{
						// img: require('@/imgs/recommend-1.png')
						id: "1",
						goodimgurl: require("../../assets/imgs/text1.png"),
						goodtintegral: "103",
						shopName: "乌鲁木齐市啦啦啦啦建材...",
					},
				],
			}
		},
		computed:{
			current: {
				get() {
					return this.currentPage
				},
				set() {}
			},
		},
		mounted() {
			this.MyAttention();
		},
		methods: {
			//删除关注
			DElete(val) {
				this.$confirm('取消关注, 是否继续?', '提示', {
				          confirmButtonText: '确定',
				          cancelButtonText: '取消',
				          type: 'warning'
				        }).then(() => {
							this.ApiRequestPost('/api/mall/auxiliary/focus-shop/delete', {
								fShopUnitID:val
							}).then(
								result => {
									this.MyAttention();
								},
								rej => {}
							);
				        }).catch(() => {
				          //this.$message({type: 'info',message: '已取消删除',showClose: false,});          
				        });
				
			
			},
			//进店
			enterTheStore(id) {
				//console.log('id', id);
				this.$router.push({
					name: 'storeList',
					query: {
						id: id
					}
				})
				// this.$router.push({
				// 	name: 'storeList',
				// 	params: {
				// 		id: id
				// 	}
				// })
			},
			followBtn() {
				this.isConcern = !this.isConcern;
			},
			handleSizeChange(val) {
				this.$emit('handleSizeChange', val)
			},
			handleCurrentChange(val) {
				this.$emit('handleCurrentChange', val);
				this.currentPage = val;
				this.MyAttention();
			
			},
			
			//我的关注
			MyAttention(){
				this.ApiRequestPost('api/mall/auxiliary/focus-shop/get-customer-focus-list', {
					 maxResultCount: this.pagesize, //每页显示10条
					 skipCount: (this.currentPage - 1) * this.pagesize, //当前页显示数据（跳过多少条数据）
					 filter: '', //模糊查询
					 sorting: ''
				}).then(
					res => {
						// res.obj.fBeginDate = this.getTime(res.obj.fBeginDate);
						// res.obj.fShopLogoPath = this.getUploadFileURL(res.obj.fShopLogoPath)
						this.$nextTick(() => {
							console.log(res,"resresresresresresres")
							this.listData = res.obj.items;
              this.total = res.obj.totalCount
							console.log(this.listData,"this.listData")
						});
					},
					error => {
						this.$message({
							type: 'success',
							message: 'error.message'
						});
					}
				);
			},
		}
	}
</script>

<style scoped="scoped">
	.MyFocus-Box {
		width: 960px;
		min-height:700px;
		background-color: #fff;
		margin-top:13px;
		
		/* margin-left: 460px; */
	}
	.MyFocus-title {
		font-weight: 700;
		font-size: 18px;
		color: #000000;
		margin-top:8px;
		padding:10px 10px;
	}
	.popup_content {
		width: 295px;
		height:auto;
		background: #ffffff;
		border: 1px solid #f2f2f2;
		border-radius: 10px;
		float: left;
		margin-right: 8px;
		margin-top: 15px;
		position: relative;
		margin-left:10px;
	}
	.MyFcous-one {
		display: flex;
	}
	.MyFocus-img img {
		display: block;
		width: 30px;
		height: 30px;
		border: 1px solid #e4e4e4;
		padding: 4px;
		margin: 14px 0px 14px 14px;
	}
	.MyFoucs-pu {
		width: 37px;
		height: 17px;
		background: #ff0000;
		font-size: 12px;
		font-family: , -400;
		font-weight: 400;
		border-radius: 5px;
		line-height: 17px;
		text-align: center;
		float: left;
		margin-top: 6px;
		color: #FFFFFF;
	}
	.shop_name {
		font-family: , -400;
		font-weight: 400;
		color: #000000;
		padding-top: 14px;

	}
	.u_mc_smallp {
		display: block;
		font-size: 12px;
		font-family: , -400;
		font-weight: 400;
		color: #666666;
		margin: 6px 0px 0px 45px;
	}
	.concer_leftbox {
		width: 75px;
		height: 24px;
		background: rgba(255, 255, 255, 0);
		border: 1px solid #0173fe;
		border-radius: 30px;
		font-family: , -400;
		font-weight: 400;
		color: #0173fe;
		text-align: center;
		margin: 0px 8px 16px 212px;
	}
	.iconfont{
		position:absolute;
		top:8px;
		right:8px;
		/* background: #cccccc; */
		color: #cccccc;
		font-size:18px;
	}
</style>
